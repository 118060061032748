import axiosClient from ".";

class DashboardAPI {
  static getDashboardStat() {
    return axiosClient.get("super_admin/api/dashboards.json");
  }
  static getDashboardCountryGraph() {
    return axiosClient.get("super_admin/api/user_locations.json");
  }
}
export default DashboardAPI;
